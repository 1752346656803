import React from "react"
import { ButtonLink } from "../structure/theme/button.component"
import { useThemeContext, colors } from "../structure/theme"
import { Secondary } from "../structure/theme/titles.component"
import styled from "styled-components"
import { Bold, flexCenter } from "../structure/theme/styles.component"
import PropTypes from "prop-types"
import { createSelfCareLink } from "./navigation.utils"
import { useTranslation } from "../translation/translate.component"

const Return = styled.span`
  color: ${colors(`secondary`)};
  text-decoration: underline;
  cursor: pointer;

  :before {
    content: ">";
    display: inline-block;
    margin-right: 5px;
  }
`

const Wrapper = styled.div`
  ${flexCenter};
  flex-direction: column;
  padding-bottom: 40px;
`

const ButtonWrapper = styled.div`
  margin: 32px 16px 16px 16px;
`

const CloseWrapper = styled.div`
  ${flexCenter};
`

const LinkSelfCare = styled(ButtonLink)`
  display: inline-flex;
`

export default function SelfcareModal ({ onClose }) {
  const {
    fonts: { sizes },
  } = useThemeContext()
  const { t } = useTranslation()

  return (
    <Wrapper data-testid="selfcare_modal">
      <Secondary component="h4" bold={900} marginBottom="10px" marginTop="40px" size={sizes.xx_large} center>
        {t(`common:navigation.wrapper_selfcare_secondary`)}
      </Secondary>
      <Bold>{t(`common:navigation.wrapper_selfcare_bold`)}</Bold>
      <span>{t(`common:navigation.wrapper_selfcare_span`)}</span>
      <span>{t(`common:navigation.wrapper_selfcare_span2`)}</span>
      <ButtonWrapper>
        <LinkSelfCare
          data-testid="selfcare_link"
          target="_blank"
          href={createSelfCareLink(APP_CONFIG.selfcare.login, `PopinEspaceClient`)}
        >
          {t(`common:navigation.wrapper_selfcare_link`)}
        </LinkSelfCare>
      </ButtonWrapper>
      <CloseWrapper>
        <Return onClick={onClose} data-testid="selfcare_close">
          {t(`common:navigation.wrapper_selfcare_close`)}
        </Return>
      </CloseWrapper>
    </Wrapper>
  )
}

SelfcareModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}
